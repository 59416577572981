
import $ from "jquery";

window.jQuery = $;
window.$ = $;

import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

window.Swiper = Swiper

import themeMedia from "./themeMedia";
themeMedia();

import headerNavigator from "./layout/headerNavigator";
headerNavigator();

import footerForm from "./layout/footerForm";
footerForm();

import footerReviews from "./layout/footerReviews";
footerReviews();

import footerBanner from "./layout/footerBanner";
footerBanner();

import sectionAbout from "./partials/sectionAbout";
sectionAbout();

import sectionSlider from "./partials/sectionSlider";
sectionSlider();

import sectionSliderServices from "./partials/sectionSliderServices";
sectionSliderServices();

import sectionCardMap from "./partials/sectionCardMap";
sectionCardMap();

import sectionCardText from "./partials/sectionCardText";
sectionCardText();

import sectionCardGallery from "./partials/sectionCardGallery";
sectionCardGallery();

import sectionGridLink from "./partials/sectionGridLink";
sectionGridLink();

import templateContatti from "./templates/templateContatti";
templateContatti();

import templateEventi from "./templates/templateEventi";
templateEventi();

import templateServiziEsclusivi from "./templates/templateServiziEsclusivi";
templateServiziEsclusivi();

import initMap from "./initMap";
window.initMap = initMap;
