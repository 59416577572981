const footerBanner = () => {
  $(() => {
    //   $(".footer-banner__list").each(function () {
    //     const $sliderList = $(this);
    //     $sliderList.slick({
    //       accessibility: false,
    //       autoplay: true,
    //       autoplaySpeed: 0,
    //       arrows: false,
    //       centerMode: true,
    //       cssEase: "linear",
    //       dots: false,
    //       draggable: false,
    //       infinite: true,
    //       mobileFirst: true,
    //       pauseOnFocus: false,
    //       pauseOnHover: true,
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       speed: 9000,
    //       swipe: false,
    //       touchMove: false,
    //       variableWidth: true,
    //       rows: 0,
    //     });
    //   });

    const blocks = document.querySelectorAll(".footer-banner__list");
    if (blocks.length > 0) {
      blocks.forEach((block) => {
        const swiper = new Swiper(block, {
          slidesPerView: "auto",
          loop: true,
          effect: "slide",
          speed: 6500,
          allowTouchMove: false,
          spaceBetween: 1,
          // freeMode: true,
          autoplay: {
            delay: 1,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
            waitForTransition: true,
            stopOnLastSlide: false,
          },
        });
      });
    }
  });
};

export default footerBanner;
